var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{staticClass:"px-20",attrs:{"gutter":24}},[_c('a-col',{staticClass:"mb-24 mt-10",attrs:{"span":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('h5',[_vm._v("List of Readers/Viewers")])]),_c('a-col',{staticClass:"mb-24 mt-10 text-right",attrs:{"span":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('download-excel',{staticClass:"btn btn-primary",attrs:{"fields":_vm.excel_fields,"fetch":_vm.exportData,"type":"csv","name":"notes_readers.xls"}},[_c('strong',[_c('svg',{staticClass:"text-black dark:text-white",staticStyle:{"position":"absolute","right":"124px","width":"18px"},attrs:{"aria-hidden":"true","xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"none","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"}})]),_vm._v(" Download Excel ")])])],1)],1),_c('a-row',{staticClass:"px-15",staticStyle:{"margin-top":"0px"},attrs:{"gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"xs":24,"sm":24,"md":24,"lg":24,"xl":16}},_vm._l((_vm.filterItems),function(option){return _c('a-badge',{key:option.uuid,staticStyle:{"font-size":"14px !important","margin":"2px 3px","padding":"5px"},attrs:{"count":("" + (option.name)),"number-style":_vm.filter == option.uuid
            ? {
                backgroundColor: '#734EBF !important',
                color: '#FFFFFF',
                paddingTop: '5px',
                paddingRight: '18px',
                paddingLeft: '18px',
                height: '32px',
                fontSize: '14px',
              }
            : {
                border: '1px solid #734EBF !important',
                color: '#734EBF',
                backgroundColor: 'transparent !important',
                paddingTop: '5px',
                paddingRight: '18px',
                paddingLeft: '18px',
                height: '32px',
                fontSize: '14px',
              }},on:{"click":function($event){return _vm.onSelectFilterItem(option.uuid)}}})}),1)],1),_c('div',{},[_c('a-row',{staticClass:"px-20",attrs:{"gutter":24}},[_c('a-col',{staticClass:"mb-24 pb-10",attrs:{"span":24,"md":24,"lg":24,"xl":24}},[_c('a-row',{staticClass:"mb-0 pb-0 mt-15",attrs:{"gutter":24}},[_c('a-col',{staticClass:"mb-0 pb-0",attrs:{"span":24,"md":24,"lg":24,"xl":24}},[_c('a-table',{staticClass:"ant-table-striped",staticStyle:{"width":"100%"},attrs:{"columns":_vm.columns,"data-source":_vm.filter == 'completed'
                  ? _vm.completedReaders
                  : _vm.filter == 'not-completed'
                  ? _vm.notCompletedReaders
                  : _vm.readers,"loading":_vm.loadingData,"pagination":_vm.pagination,"rowKey":function (record) { return record.uuid; },"width":"100%"},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"s_no",fn:function(s_no, record, index){return [_vm._v(" "+_vm._s((_vm.pagination.current - 1) * _vm.pagination.pageSize + index + 1)+" ")]}},{key:"fullName",fn:function(row){return [_vm._v(" "+_vm._s(row.firstName)+" "+_vm._s(row.lastName)+" ")]}},{key:"identification",fn:function(row){return [_vm._v(" "+_vm._s(row.identification)+" ")]}},{key:"lessonNum",fn:function(row){return [_vm._v(" "+_vm._s(_vm.getLessonNum(row.uuid))+" ")]}},{key:"hasJoined",fn:function(row){return [_c('a-badge',{staticStyle:{"font-size":"10px !important","margin":"2px 3px"},attrs:{"count":row.hasJoined ? 'Yes' : 'No',"number-style":row.hasJoined
                      ? {
                          backgroundColor: '#734EBF',
                          color: '#fff',
                          boxShadow: '0 0 0 1px #734EBF inset',
                        }
                      : { backgroundColor: '#D9D9D9', color: '#444' }}})]}},{key:"verifiedAt",fn:function(row){return [_vm._v(" "+_vm._s(row.verifiedAt != null ? _vm.$Moment(row.lastSeen).format("DD MMM YYYY HH:mm") : "")+" ")]}},{key:"action",fn:function(row){return [_c('a-button',{staticClass:"ml-15",staticStyle:{"border-radius":"20px"},attrs:{"id":"deleteBtn","type":"text","size":"small"},on:{"click":function($event){return _vm.confirmMemberDeletion(row.uuid)}}},[_c('a-icon',{attrs:{"type":"delete","theme":"outlined"}})],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }