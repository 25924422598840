<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
  <div>
    <a-row :gutter="24" class="px-20">
      <a-col :span="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb-24 mt-10">
        <h5>List of Quiz Takers</h5>
      </a-col>

      <a-col
        :span="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="mb-24 mt-10 text-right"
      >
        <download-excel
          class="btn btn-secondary"
          :fields="excel_fields"
          :fetch="exportData"
          type="csv"
          name="quiz_takers.xls"
        >
          <strong>
            <svg
              class="text-black dark:text-white"
              style="position: absolute; right: 124px; width: 18px"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"
              />
            </svg>

            Download Excel
          </strong>
        </download-excel>
        <!-- <a-button :loading="loadingDownload" id="deleteBtn" class="text-danger" @click="confirmAllMembersDeletion" style="">
                    <a-icon type="download" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; Delete All
                </a-button> -->

        <!-- <a-button :loading="loadingDownload" id="btnBordered" type="text" class="mr-20" @click="downloadCSV" style="">
                    <a-icon type="download" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; CSV Template
                </a-button> -->

        <!-- <a-button :loading="loadingDownload" type="primary" @click="onUploadStudentIddentifications" style="">
                    <a-icon type="upload" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; Upload Identifications
                </a-button> -->
      </a-col>
    </a-row>

    <a-row :gutter="24" class="px-15" style="margin-top: 0px">
      <a-col
        :span="24"
        :xs="24"
        :sm="16"
        :md="16"
        :lg="16"
        :xl="16"
        class="mb-24"
      >
        <a-badge
          v-for="option in filterItems"
          :key="option.uuid"
          style="font-size: 14px !important; margin: 2px 3px; padding: 5px"
          :count="`${option.name}`"
          @click="onSelectFilterItem(option.uuid)"
          :number-style="
            filter == option.uuid
              ? {
                  backgroundColor: '#734EBF !important',
                  color: '#FFFFFF',
                  paddingTop: '5px',
                  paddingRight: '18px',
                  paddingLeft: '18px',
                  height: '32px',
                  fontSize: '14px',
                }
              : {
                  border: '1px solid #734EBF !important',
                  color: '#734EBF',
                  backgroundColor: 'transparent !important',
                  paddingTop: '5px',
                  paddingRight: '18px',
                  paddingLeft: '18px',
                  height: '32px',
                  fontSize: '14px',
                }
          "
        />

        Select Quiz: &nbsp;&nbsp;&nbsp;&nbsp;
        <a-select
          v-model="quizUuid"
          style="width: 300px"
          placeholder="Select Quiz"
          @change="onSelectQuiz($event)"
        >
          <a-select-option
            v-for="option in quizzes"
            :value="option.uuid"
            :key="option.uuid"
          >
            {{ option.title }}
          </a-select-option>
        </a-select>
      </a-col>

      <a-col
        :span="24"
        :xs="24"
        :sm="8"
        :md="8"
        :lg="8"
        :xl="8"
        class="mb-24 text-right"
      >
        <a-input-search
          placeholder="search name..."
          style="max-width: 250px"
          v-model="searchQuery"
        >
        </a-input-search>
      </a-col>

      <a-col
        :span="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="mb-24 mt-10 text-right"
      >
        <!-- <download-excel
                    class="btn btn-secondary"
                    :fields="excel_fields"
                    :fetch="exportData"
                    type="csv"
                    name="notes_readers.xls"
                    >
                    <strong>Download Excel</strong>
                </download-excel> -->
        <!-- <a-button :loading="loadingDownload" id="deleteBtn" class="text-danger" @click="confirmAllMembersDeletion" style="">
                    <a-icon type="download" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; Delete All
                </a-button> -->

        <!-- <a-button :loading="loadingDownload" id="btnBordered" type="text" class="mr-20" @click="downloadCSV" style="">
                    <a-icon type="download" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; CSV Template
                </a-button> -->

        <!-- <a-button :loading="loadingDownload" type="primary" @click="onUploadStudentIddentifications" style="">
                    <a-icon type="upload" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; Upload Identifications
                </a-button> -->
      </a-col>
    </a-row>

    <a-row :gutter="24" class="px-15" style="margin-top: 0px"> </a-row>

    <div class="">
      <a-row :gutter="24" class="px-20">
        <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 pb-10">
          <!-- <a-card> -->

          <a-row :gutter="24" class="mb-0 pb-0 mt-15">
            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
              <a-table
                class="ant-table-striped"
                :columns="columns"
                :data-source="filteredUsers"
                :loading="loadingData"
                :pagination="pagination"
                :rowKey="(record) => record.user"
                @change="handleTableChange"
                width="100%"
                style="width: 100%"
              >
                <template slot="s_no" slot-scope="s_no, record, index">
                  {{
                    (pagination.current - 1) * pagination.pageSize + index + 1
                  }}
                </template>

                <template slot="fullName" slot-scope="row">
                  {{ row.firstName }} {{ row.lastName }}
                </template>

                <template slot="identification" slot-scope="row">
                  {{ row.identification }}
                </template>

                <template slot="score" slot-scope="row">
                  {{ row.score }}%
                </template>

                <template slot="quizDate" slot-scope="row">
                  {{ row.quizDate }}
                </template>

                <template slot="hasJoined" slot-scope="row">
                  <a-badge
                    style="font-size: 10px !important; margin: 2px 3px"
                    :count="row.hasJoined ? 'Yes' : 'No'"
                    :number-style="
                      row.hasJoined
                        ? {
                            backgroundColor: '#734EBF',
                            color: '#fff',
                            boxShadow: '0 0 0 1px #734EBF inset',
                          }
                        : { backgroundColor: '#D9D9D9', color: '#444' }
                    "
                  />
                </template>

                <template slot="verifiedAt" slot-scope="row">
                  {{
                    row.verifiedAt != null
                      ? $Moment(row.lastSeen).format("DD MMM YYYY HH:mm")
                      : ""
                  }}
                </template>

                <template slot="action" slot-scope="row">
                  <a-button
                    id="deleteBtn"
                    type="text"
                    class="ml-15"
                    size="small"
                    @click="confirmMemberDeletion(row.uuid)"
                    style="border-radius: 20px"
                  >
                    <a-icon type="delete" theme="outlined" />
                  </a-button>
                </template>
              </a-table>
            </a-col>
          </a-row>

          <!-- </a-card> -->
        </a-col>

        <!-- <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10 text-right">
                    <a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
                </a-col> -->
      </a-row>
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";
import { notification } from "ant-design-vue";

const columns = [
  {
    title: "S/N",
    scopedSlots: { customRender: "s_no" },
    width: 35,
  },
  {
    title: "Full Name",
    scopedSlots: { customRender: "fullName" },
    width: 200,
  },
  {
    title: "Identification",
    scopedSlots: { customRender: "identification" },
    width: 100,
  },
  {
    title: "Marks",
    scopedSlots: { customRender: "score" },
    width: 100,
  },
  {
    title: "Date",
    scopedSlots: { customRender: "quizDate" },
    width: 100,
  },
  // {
  //     title: 'Action',
  //     scopedSlots: { customRender: 'action' },
  //     width: 80
  // },
];

export default {
  components: {},
  props: ["classDetails", "isCreator"],
  data() {
    return {
      columns,
      loadingData: false,
      loadingDownload: false,
      filter: "all",
      filterItems: [
        { uuid: "all", name: "All" },
        { uuid: "passed", name: "Passed" },
        { uuid: "failed", name: "Failed" },
      ],

      searchQuery: "",
      quizzes: [],
      userDetails: {},
      institution: {},

      institutionMembers: [],
      classMembers: [],

      attemptors: [],

      allQuizTakers: [],
      passedQuizTakers: [],
      failedQuizTakers: [],

      readers: [],
      completedReaders: [],
      notCompletedReaders: [],

      quizLoading: false,
      quizUuid: null,

      lessons: [],
      currentPage: 1,

      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },

      // pagination: {
      // 	search: '',
      //     perPage: 10,
      //     currentPage: 1,
      //     totalPages: 1,
      //     perPageOptions: [10, 20, 30, 50],
      //     total: 0
      // },

      excel_fields: {
        "First Name": "firstName",
        "Last Name": "lastName",
        Identification: "identification",
        "Email Address": "emailAddress",
        "Phone Number": "phoneNumber",
        Score: "score",
        Date: "quizDate",
      },
    };
  },
  watch: {
    // pagination: {

    //     handler() {
    //         // this.getNotesReaders();
    // 		console.log(this.pagination.currentPage)
    //     },
    // },

    deep: true,
  },

  computed: {
    filteredUsers() {
      if (this.filter == "passed") {
        return this.searchQuery.length > 0
          ? this.passedQuizTakers.filter(
              (user) =>
                user.identification != null &&
                user.identification
                  .toLowerCase()
                  .includes(this.searchQuery.toLowerCase())
            )
          : this.passedQuizTakers;
      } else if (this.filter == "failed" && this.searchQuery.length > 0) {
        return this.searchQuery.length > 0
          ? this.failedQuizTakers.filter(
              (user) =>
                user.identification != null &&
                user.identification
                  .toLowerCase()
                  .includes(this.searchQuery.toLowerCase())
            )
          : this.failedQuizTakers;
      } else {
        return this.searchQuery.length > 0
          ? this.allQuizTakers.filter(
              (user) =>
                user.identification != null &&
                user.identification
                  .toLowerCase()
                  .includes(this.searchQuery.toLowerCase())
            )
          : this.allQuizTakers;
      }
    },
  },
  created() {
    // this.initializeFilter();
    // this.getInstitutionDetails();
    this.getInstitutionMembers();
    // this.getQuizzes();
  },
  methods: {
    formatName(name) {
      let formattedName =
        name != null
          ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}`
          : name;
      return formattedName;
    },

    handleTableChange(pagination, filters, sorter) {
      // console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },

    async exportData() {
      return this.filter == "passed"
        ? this.passedQuizTakers
        : this.filter == "failed"
        ? this.failedQuizTakers
        : this.allQuizTakers;
    },

    submitSearch() {},

    cancelSearch() {},

    onSelectQuiz(quizUuid) {
      this.loadingData = true;

      let quiz = this.quizzes.filter((quiz) => quiz.uuid == quizUuid)[0];

      let quizTakers =
        quiz.attempts.length == 0
          ? []
          : quiz.attempts.map((attempt) => {
              let identification = this.getTillDetails(attempt.user);

              let userDetails = this.getAttemptorDetails(attempt.user);

              if (
                identification == null &&
                userDetails != null &&
                userDetails.phoneNumber != null
              ) {
                identification = this.getTillDetailsByPhone(
                  userDetails.phoneNumber
                );
              } else if (
                identification == null &&
                userDetails != null &&
                userDetails.firstName != null &&
                userDetails.lastName != null
              ) {
                identification = this.getTillDetailsByFullName(
                  `${userDetails.firstName.toLowerCase()} ${userDetails.lastName.toLowerCase()}`
                );
              } else if (
                identification == null &&
                userDetails != null &&
                userDetails.lastName != null
              ) {
                identification = this.getTillDetailsByLastName(
                  userDetails.lastName.toLowerCase()
                );
              }
              let quizDate =
                attempt.date != null
                  ? this.$Moment(attempt.date).format("DD MMM YYYY")
                  : `From ${this.$Moment("2024-11-05").format(
                      "DD MMM YYYY"
                    )} and below`;

              return { ...attempt, quizDate, ...userDetails, identification };
            });

      setTimeout(() => {
        this.allQuizTakers = quizTakers.reverse();

        this.passedQuizTakers = quizTakers
          .filter((quizTaker) => quizTaker.score >= 80)
          .reverse();

        this.failedQuizTakers = quizTakers
          .filter((quizTaker) => quizTaker.score < 80)
          .reverse();

        this.pagination.current = 1;
        this.pagination.total =
          this.filter == "passed"
            ? this.passedQuizTakers.length
            : this.filter == "failed"
            ? this.failedQuizTakers.length
            : this.allQuizTakers.length;

        this.loadingData = false;
      }, 1000);
    },

    getAttemptorDetails(userUuid) {
      let results = this.attemptors.filter((member) => member.uuid == userUuid);

      return results.length > 0 ? results[0] : null;
    },

    getTillDetails(userUuid) {
      let results = this.institutionMembers.filter(
        (member) => member.user != null && member.user == userUuid
      );

      return results.length > 0 ? results[0].externalId : null;
    },

    getTillDetailsByLastName(lastName) {
      let results = this.institutionMembers.filter((member) =>
        member.fullName.toLowerCase().includes(lastName)
      );

      return results.length == 1 ? results[0].externalId : null;
    },

    getTillDetailsByFullName(fullName) {
      let results = this.institutionMembers.filter((member) =>
        member.fullName.toLowerCase().includes(fullName)
      );

      return results.length == 1 ? results[0].externalId : null;
    },

    getTillDetailsByPhone(phone) {
      let results = this.institutionMembers.filter(
        (member) => member.externalId == phone
      );

      return results.length == 1 ? results[0].externalId : null;
    },

    async onSelectFilterItem(item) {
      this.loadingData = true;

      this.pagination.current = 1;
      this.pagination.total =
        item == "passed"
          ? this.passedQuizTakers.length
          : item == "failed"
          ? this.failedQuizTakers.length
          : this.allQuizTakers.length;

      setTimeout(() => {
        this.filter = item;

        this.loadingData = false;
      }, 1000);
    },

    async getInstitutionMembers() {
      this.loadingData = true;

      const currentPage = 1;
      const perPage = 10000000;

      let url = `${this.$BACKEND_URL}/institutions/users?clazz=${this.$route.params.uuid}&hasJoined=true&page=${currentPage}&size=${perPage}`;

      this.$AXIOS
        .get(url)
        .then(async (response) => {
          if (response.status == 200) {
            this.institutionMembers = response.data.data;
          }

          this.getQuizzes();

          this.loadingData = false;
        })
        .catch(async (error) => {
          this.loadingData = false;

          if (error.response && error.response.status == 401) {
            await localStorage.setItem("user_token", null);
            await localStorage.setItem("user_details", null);
            await localStorage.setItem("institution_details", null);

            this.$router.push(`/sign-in`);
          }
        });
    },

    async getQuizzes() {
      this.loadingData = true;

      const currentPage = 1;
      const perPage = 10000000;
      //
      let url = `${this.$BACKEND_URL}/quizzes/attempted-report?clazz=${this.$route.params.uuid}&populate=true&page=${currentPage}&size=${perPage}`;

      this.$AXIOS
        .get(url)
        .then(async (response) => {
          if (response.status == 200) {
            this.quizzes = response.data.data;

            let results = response.data.data
              .filter((quiz) => quiz.attemptors != null)
              .map((quiz) => [...quiz.attemptors])
              .flat(1);

            // && index === self.findIndex((o) => o.uuid === topic.uuid)
            let newResults = results.filter(
              (obj, index, self) =>
                index === self.findIndex((o) => o.uuid === obj.uuid)
            );

            this.attemptors = newResults;

            // this.attemptors = newResults.map((result) => {
            // result.lessonNum = this.getLessonNum(result.uuid);

            // result.date = this.$Moment().format('DD MMM YYYY');
            // let userDetails = this.classMembers.filter(member => member.uuid == result.user)[0]

            // console.log(result)

            // result.fullName = result.firstName != null && result.lastName != null ? `${result.firstName} ${result.lastName}` : `${userDetails.firstName} ${userDetails.lastName}`;
            // result.tillNumber = tillDetails.length > 0 ? tillDetails[0].externalId : null;
            // result.quizDate = result.date != null ? this.$Moment(result.date).format('DD MMM YYYY') : this.$Moment('2024-11-05').format('DD MMM YYYY');

            // console.log(result)

            // return result
            // });

            if (response.data.data != null && response.data.data.length > 0) {
              this.quizUuid = response.data.data[0].uuid;

              this.onSelectQuiz(response.data.data[0].uuid);
            }

            // this.completedReaders = this.readers.filter(read => this.getLessonNum(read.uuid) == this.lessons.length);

            // this.notCompletedReaders = this.readers.filter(read => this.getLessonNum(read.uuid) != this.lessons.length);
          }

          this.loadingData = false;
        })
        .catch(async (error) => {
          this.loadingData = false;

          if (error.response && error.response.status == 401) {
            await localStorage.setItem("user_token", null);
            await localStorage.setItem("user_details", null);
            await localStorage.setItem("institution_details", null);

            this.$router.push(`/sign-in`);
          }
        });
    },

    async getMembers() {
      this.loadingData = true;

      const currentPage = 1;
      const perPage = 100000000;

      let url = `${this.$BACKEND_URL}/members?clazz=${this.$route.params.uuid}&populate=true&page=${currentPage}&size=${perPage}`;

      this.$AXIOS
        .get(url)
        .then(async (response) => {
          if (response.status == 200) {
            this.classMembers = response.data.data;
          }

          this.loadingData = false;

          this.getQuizzes();
        })
        .catch(async (error) => {
          this.loadingData = false;

          if (error.response && error.response.status == 401) {
            await localStorage.setItem("user_token", null);
            await localStorage.setItem("user_details", null);
            await localStorage.setItem("institution_details", null);

            this.$router.push(`/sign-in`);
          }
        });
    },

    // async getNotesReaders() {

    // 	this.loadingData = true;

    //     const currentPage = 1;
    //     const perPage = 10000000;

    //     let  url = `${this.$BACKEND_URL}/topics/report?clazz=${this.$route.params.uuid}&isSubtopic=true&&populate=true&page=${currentPage}&size=${perPage}`;

    //     this.$AXIOS.get(url).then(async(response) => {
    //         if (response.status == 200) {

    //             this.lessons = response.data.data;

    // 			let results = (response.data.data.filter((topic) => topic.readers != null).map(topic =>  [...topic.readers])).flat(1);

    //             // && index === self.findIndex((o) => o.uuid === topic.uuid)
    //             let newResults = results.filter((obj, index, self) =>
    //                 index === self.findIndex((o) => o.uuid === obj.uuid)
    //             );

    //             this.readers = newResults.map((result) => {
    //                 result.lessonNum = this.getLessonNum(result.uuid);

    //                 result.date = this.$Moment().format('DD MMM YYYY');

    //                 return result
    //             });

    //             this.completedReaders = this.readers.filter(read => this.getLessonNum(read.uuid) == this.lessons.length);

    //             this.notCompletedReaders = this.readers.filter(read => this.getLessonNum(read.uuid) != this.lessons.length);

    //         }

    // 		this.loadingData = false;

    //     }).catch(async(error) => {

    // 		this.loadingData = false;

    //         if(error.response && error.response.status == 401) {
    // 			await localStorage.setItem("user_token", null);
    // 			await localStorage.setItem("user_details", null)
    // 			await localStorage.setItem("institution_details", null)

    //             this.$router.push(`/sign-in`);
    //         }
    //     });
    // },

    getLessonNum(userUuid) {
      let num = 0;
      this.lessons.forEach((element) => {
        if (element.completed != null && element.completed.includes(userUuid)) {
          num = num + 1;
        }
      });

      return num;
    },

    notify(type, message) {
      notification[type]({
        message: message,
        description: "",
        placement: "topRight",
        duration: 5,
      });
    },
  },
};
</script>

<style>
.double-bounce1[data-v-33aee302],
.double-bounce2[data-v-33aee302] {
  background-color: #734ebf !important;
  height: 70px;
  width: 70px;
}

.ant-pagination-item-active a {
  color: #ffffff !important;
  background-color: #734ebf;
}

#classImg {
  /* border-radius: 10px; */
}

a:hover {
  color: inherit !important;
}

#txtBtnCancel,
#txtBtnCancel:hover {
  color: #b37fea;
  border: none;
}

#txtBtnDelete,
#txtBtnDelete:hover {
  color: #f5212d;
  border: none;
}

#deleteBtn,
#deleteBtn:hover {
  background-color: inherit !important;
  border: none;
  color: #f5222d;
}
</style>