<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
  <div>
    <a-row :gutter="24" class="px-20">
      <a-col :span="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb-24 mt-10">
        <h5>List of Class Attendance</h5>
      </a-col>

      <a-col
        :span="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="mb-24 mt-10 text-right"
      >
        <a-date-picker
          v-model="filterDate"
          format="DD MMM YYYY"
          class="ml-1"
          @change="onSelectDate($event)"
          style="
            width: 200px;
            display: inline-block !important;
            margin-right: 80px;
          "
          placeholder="Select Date"
        />

        <download-excel
          class="btn btn-primary"
          :fields="excel_fields"
          :fetch="exportData"
          type="csv"
          name="class_attendance.xls"
          style="display: inline-block !important"
        >
          <strong>
            <svg
              class="text-black dark:text-white"
              style="position: absolute; right: 124px; width: 18px"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"
              />
            </svg>

            Download Excel
          </strong>
        </download-excel>
        <!-- <a-button :loading="loadingDownload" id="deleteBtn" class="text-danger" @click="confirmAllMembersDeletion" style="">
                    <a-icon type="download" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; Delete All
                </a-button> -->

        <!-- <a-button :loading="loadingDownload" id="btnBordered" type="text" class="mr-20" @click="downloadCSV" style="">
                    <a-icon type="download" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; CSV Template
                </a-button> -->

        <!-- <a-button :loading="loadingDownload" type="primary" @click="onUploadStudentIddentifications" style="">
                    <a-icon type="upload" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; Upload Identifications
                </a-button> -->
      </a-col>
    </a-row>

    <!-- <a-row :gutter="24" class="px-15" style="margin-top: 0px;">
            <a-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="16" class="mb-24">
                <a-badge 
                    v-for="option in filterItems"
                    :key="option.uuid"
                    style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                    :count="`${option.name}`" 
                    @click="onSelectFilterItem(option.uuid)"
                    :number-style="filter == option.uuid ? 
                        {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'} : 
                        {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: 'transparent !important', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'}"/>
            </a-col>
		</a-row> -->
    <!-- {{ classMembers }} -->

    <div class="">
      <a-row :gutter="24" class="px-20">
        <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 pb-10">
          <!-- <a-card> -->

          <a-row :gutter="24" class="mb-0 pb-0 mt-15">
            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
              <a-table
                class="ant-table-striped"
                :columns="columns"
                :data-source="classMembers"
                :loading="loadingData"
                :pagination="pagination"
                :rowKey="(record) => record._id"
                width="100%"
                @change="handleTableChange"
                style="width: 100%"
              >
                <template slot="s_no" slot-scope="s_no, record, index">
                  {{
                    (pagination.current - 1) * pagination.pageSize + index + 1
                  }}
                </template>

                <template slot="fullName" slot-scope="row">
                  {{
                    row.member != null
                      ? `${row.member.firstName} ${row.member.lastName}`
                      : "N/A"
                  }}
                </template>

                <template slot="identification" slot-scope="row">
                  {{ row.identification }}
                </template>

                <template slot="lessonNum" slot-scope="row">
                  {{ getLessonNum(row.uuid) }}
                </template>

                <template slot="date" slot-scope="row">
                  {{ $Moment(row.createdAt).format("DD MMM YYYY") }}
                </template>

                <template slot="time" slot-scope="row">
                  {{ $Moment(row.createdAt).format("hh:mm A") }}
                </template>

                <template slot="hasJoined" slot-scope="row">
                  <a-badge
                    style="font-size: 10px !important; margin: 2px 3px"
                    :count="row.hasJoined ? 'Yes' : 'No'"
                    :number-style="
                      row.hasJoined
                        ? {
                            backgroundColor: '#734EBF',
                            color: '#fff',
                            boxShadow: '0 0 0 1px #734EBF inset',
                          }
                        : { backgroundColor: '#D9D9D9', color: '#444' }
                    "
                  />
                </template>

                <template slot="verifiedAt" slot-scope="row">
                  {{
                    row.verifiedAt != null
                      ? $Moment(row.lastSeen).format("DD MMM YYYY HH:mm")
                      : ""
                  }}
                </template>

                <template slot="action" slot-scope="row">
                  <a-button
                    id="deleteBtn"
                    type="text"
                    class="ml-15"
                    size="small"
                    @click="confirmMemberDeletion(row.uuid)"
                    style="border-radius: 20px"
                  >
                    <a-icon type="delete" theme="outlined" />
                  </a-button>
                </template>
              </a-table>
            </a-col>
          </a-row>

          <!-- </a-card> -->
        </a-col>

        <!-- <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10 text-right">
                    <a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
                </a-col> -->
      </a-row>
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";
import { notification } from "ant-design-vue";
import dayjs from "dayjs";

const columns = [
  {
    title: "S/N",
    scopedSlots: { customRender: "s_no" },
    width: 35,
  },
  {
    title: "Full Name",
    scopedSlots: { customRender: "fullName" },
    width: 200,
  },
  {
    title: "Identification",
    scopedSlots: { customRender: "identification" },
    width: 100,
  },
  // {
  //     title: 'No. of Lessons Read',
  //     scopedSlots: { customRender: 'lessonNum' },
  //     width: 200
  // },
  {
    title: "Date",
    scopedSlots: { customRender: "date" },
    width: 100,
  },

  {
    title: "Time",
    scopedSlots: { customRender: "time" },
    width: 100,
  },
  // {
  //     title: 'Action',
  //     scopedSlots: { customRender: 'action' },
  //     width: 80
  // },
];

export default {
  components: {},
  props: ["classDetails", "isCreator"],
  data() {
    return {
      columns,
      loadingData: false,
      loadingDownload: false,
      filter: "all",
      filterItems: [
        { uuid: "all", name: "All", icon: " 🎓" },
        { uuid: "completed", name: "Completed", icon: " 👤" },
        { uuid: "not-completed", name: "Not Completed", icon: " ❤️" },
      ],

      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },

      userDetails: {},
      institution: {},
      readers: [],
      completedReaders: [],
      notCompletedReaders: [],

      institutionMembers: [],

      classMembersJoined: [],

      filterDate: this.$Moment().format(),

      classMembers: [],
      currentPage: 1,
      // pagination: {
      // 	search: '',
      //     perPage: 10,
      //     currentPage: 1,
      //     totalPages: 1,
      //     perPageOptions: [10, 20, 30, 50],
      //     total: 0
      // },

      excel_fields: {
        "First Name": "firstName",
        "Last Name": "lastName",
        Identification: "identification",
        "Email Address": "emailAddress",
        "Phone Number": "phoneNumber",
        Date: "date",
        Time: "time",
      },
    };
  },
  watch: {
    // pagination: {

    //     handler() {
    //         // this.getNotesReaders();
    // 		console.log(this.pagination.currentPage)
    //     },
    // },

    deep: true,
  },
  mounted() {
    this.defaultDate();
  },
  created() {
    // this.initializeFilter();
    // this.getInstitutionDetails();
    // this.getClassAttendanceMembers()
    this.getInstitutionMembers();
  },
  methods: {
    defaultDate() {
      // this.filterDate = this.$Moment().format();
    },

    handleTableChange(pagination, filters, sorter) {
      // console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },

    formatName(name) {
      let formattedName =
        name != null
          ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}`
          : name;
      return formattedName;
    },

    async onSelectFilterItem(item) {
      this.loadingData = true;

      setTimeout(() => {
        this.filter = item;

        this.loadingData = false;
      }, 2000);
    },

    async exportData() {
      return this.classMembers;
    },

    submitSearch() {},

    cancelSearch() {},

    onSelectDate(selectedDate) {
      if (selectedDate != null) {
        // console.log(selectedDate)

        this.classMembers = [];

        // console.log(this.classMembers)

        this.loadingData = true;

        let date =
          selectedDate.$d != null
            ? this.$Moment(selectedDate.$d).format("YYYY-MM-DD")
            : selectedDate._d != null
            ? this.$Moment(selectedDate._d).format("YYYY-MM-DD")
            : null;

        setTimeout(() => {
          this.getClassAttendanceMembers(date);
        }, 2000);
      }
    },

    async getInstitutionMembers() {
      this.loadingData = true;

      const currentPage = 1;
      const perPage = 10000000;

      let url = `${this.$BACKEND_URL}/institutions/users?clazz=${this.$route.params.uuid}&hasJoined=true&page=${currentPage}&size=${perPage}`;

      this.$AXIOS
        .get(url)
        .then(async (response) => {
          if (response.status == 200) {
            this.institutionMembers = response.data.data;
          }

          this.getMembers();

          // this.loadingData = false;
        })
        .catch(async (error) => {
          this.loadingData = false;

          if (error.response && error.response.status == 401) {
            await localStorage.setItem("user_token", null);
            await localStorage.setItem("user_details", null);
            await localStorage.setItem("institution_details", null);

            this.$router.push(`/sign-in`);
          }
        });
    },

    async getMembers() {
      this.loadingData = true;

      const currentPage = 1;
      const perPage = 100000000;

      let url = `${this.$BACKEND_URL}/members?clazz=${this.$route.params.uuid}&populate=true&page=${currentPage}&size=${perPage}`;

      this.$AXIOS
        .get(url)
        .then(async (response) => {
          if (response.status == 200) {
            this.classMembersJoined = response.data.data;
          }

          this.getClassAttendanceMembers(null);
        })
        .catch(async (error) => {
          this.loadingData = false;

          if (error.response && error.response.status == 401) {
            await localStorage.setItem("user_token", null);
            await localStorage.setItem("user_details", null);
            await localStorage.setItem("institution_details", null);

            this.$router.push(`/sign-in`);
          }
        });
    },

    async getClassAttendanceMembers(selectedDate) {
      this.loadingData = true;

      const currentPage = 1;
      const perPage = 10000000;

      let createdAt =
        selectedDate != null
          ? selectedDate
          : this.$Moment().format("YYYY-MM-DD");

      let url = `${this.$BACKEND_URL}/classes/attendance/filter?clazz=${this.$route.params.uuid}&createdAt=${createdAt}&page=${currentPage}&size=${perPage}`;

      this.$AXIOS
        .get(url)
        .then(async (response) => {
          if (response.status == 200) {
            this.pagination.total = response.data.data.data.length;

            this.classMembers = response.data.data.data.map((member) => {
              // console.log(member)
              // let userFound = this.classMembersJoined.filter(memb => memb.uuid == member.user)
              let identification = this.getTillDetails(member.user);

              if (
                identification == null &&
                member.member != null &&
                member.member.lastName != null
              ) {
                identification = this.getTillDetailsByName(
                  member.member.lastName.toLowerCase()
                );
              }

              // console.log(identification)

              let date =
                member.createdAt != null
                  ? this.$Moment(member.createdAt).format("DD MMM YYYY")
                  : this.$Moment().format("DD MMM YYYY");
              let time =
                member.createdAt != null
                  ? this.$Moment(member.createdAt).format("hh:mm A")
                  : this.$Moment().format("hh:mm A");

              let firstName =
                member.member != null ? member.member.firstName : "";
              let lastName =
                member.member != null ? member.member.lastName : "";
              let phoneNumber =
                member.member != null ? member.member.phoneNumber : "";
              let emailAddress =
                member.member != null ? member.member.emailAddress : "";

              return {
                ...member,
                date,
                time,
                firstName,
                lastName,
                emailAddress,
                phoneNumber,
                identification,
              };
            });

            // console.log(response.data.data)

            this.loadingData = false;
            // this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
            // this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
            // this.pagination.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
            // this.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
          }
        })
        .catch(async (error) => {
          this.loadingData = false;

          if (error.response && error.response.status == 401) {
            await localStorage.setItem("user_token", null);
            await localStorage.setItem("user_details", null);
            await localStorage.setItem("institution_details", null);

            this.$router.push(`/sign-in`);
          }
        });
    },

    getTillDetails(userUuid) {
      let results = this.institutionMembers.filter(
        (member) => member.user != null && member.user == userUuid
      );

      // console.log(results)

      return results.length > 0 ? results[0].externalId : null;
    },

    getTillDetailsByName(lastName) {
      let results = this.institutionMembers.filter((member) =>
        member.fullName.toLowerCase().includes(lastName)
      );

      return results.length == 1 ? results[0].externalId : null;
    },

    notify(type, message) {
      notification[type]({
        message: message,
        description: "",
        placement: "topRight",
        duration: 5,
      });
    },
  },
};
</script>

<style>
.double-bounce1[data-v-33aee302],
.double-bounce2[data-v-33aee302] {
  background-color: #734ebf !important;
  height: 70px;
  width: 70px;
}

.ant-pagination-item-active a {
  color: #ffffff !important;
  background-color: #734ebf;
}

#classImg {
  /* border-radius: 10px; */
}

a:hover {
  color: inherit !important;
}

#txtBtnCancel,
#txtBtnCancel:hover {
  color: #b37fea;
  border: none;
}

#txtBtnDelete,
#txtBtnDelete:hover {
  color: #f5212d;
  border: none;
}

#deleteBtn,
#deleteBtn:hover {
  background-color: inherit !important;
  border: none;
  color: #f5222d;
}
</style>